import React from 'react';
import './styles.scss';

interface P {
}

interface S {

}

class PrivacyPolicy extends React.Component<P,S> {
    render() {
        return (
            <div className='privacy-policy-page'>
                <p><strong>Last Updated: 09 September 2023</strong></p>
                <p>Thank you for using Sollinked!</p>
                <p>This Privacy Policy describes how we collect, use, process, and disclose your information, including personal information, in conjunction with your access to and use of the Sollinked Platform.</p>
                <p>If you see an undefined term in this Privacy Policy (such as “Listing” or “Sollinked Platform”), it has the same definition as in our&nbsp;<a href="/terms">Terms of Service</a>.</p>
                <p>When this policy mentions “Sollinked,” “we,” “us,” or “our,” it refers to the Sollinked company that is responsible for your information under this Privacy Policy (the “<strong>Data Controller</strong>”).</p>
                <p><strong>1. INFORMATION WE COLLECT</strong></p>
                <p>There are three general categories of information we collect.</p>
                <p><strong>1.1 Information You Give to Us.</strong></p>
                <p>1.1.1 Information that is necessary for the use of the Sollinked Platform.</p>
                <p>We ask for and collect the following personal information about you when you use the Sollinked Platform. This information is necessary for the adequate performance of the contract between you and us and to allow us to comply with our legal obligations. Without it, we may not be able to provide you with all the requested services.</p>
                <ul>
                <li><strong>Account Information.</strong>&nbsp;When you sign up for an Sollinked Account, we require certain information such as your first name, last name, email address, and date of birth.</li>
                <li><strong>Profile Information.</strong>&nbsp;To use certain features of the Sollinked Platform (such as booking), we may ask you to provide additional information, which may include your address, phone number, and a profile picture.</li>
                <li><strong>Identity Verification Information.</strong>&nbsp;To help create and maintain a trusted environment, we may collect identity verification information (such as images of your government issued ID, passport, national ID card, or driving license, as permitted by applicable laws) or other authentication information.</li>
                <li><strong>Payment Information.&nbsp;</strong>To use certain features of the Sollinked Platform (such as booking), we may require you to provide certain financial information (like your bank account or credit card information) in order to facilitate the processing of payments.</li>
                <li><strong>Communications with Sollinked.</strong>&nbsp;When you communicate with Sollinked, we collect information about your communication and any information you choose to provide.</li>
                </ul>
                <p><u>1.1.2 Information you choose to give us.</u></p>
                <p>You may choose to provide us with additional personal information in order to obtain a better user experience when using Sollinked Platform. This additional information will be processed based on your consent.</p>
                <ul>
                <li><strong>Additional Profile Information.&nbsp;</strong>You may choose to provide additional information as part of your Sollinked profile (such as gender, preferred language(s), city, and a personal description). Some of this information as indicated in your Account settings is part of your public profile page, and will be publicly visible to others.</li>
                <li><strong>Other Information.</strong>&nbsp;You may otherwise choose to provide us information when you fill in a form, conduct a search, update or add information to your Sollinked Account, respond to surveys, post to community forums, participate in promotions, or use other features of the Sollinked Platform.</li>
                </ul>
                {/* <p><u>1.1.3 Information that is necessary for the use of the Payment Services.</u></p>
                <p>The Data Controller needs to collect the following information, as it is necessary for the adequate performance of the contract with you and to comply with applicable law (such as anti-money laundering regulations). Without it, you will not be able to use Payment Services:</p>
                <ul>
                <li><strong>Payment Information.&nbsp;</strong>When you use the Payment Services, the Payments Data Controller requires certain financial information (like your bank account or credit card information) in order to process payments and comply with applicable law.</li>
                </ul> */}
                <p><strong>1.2 Information We Automatically Collect from Your Use of the Sollinked Platform and Payment Services.</strong></p>
                <p>When you use the Sollinked Platform and the Payment Services, we automatically collect information, including personal information, about the services you use and how you use them. This information is necessary for the adequate performance of the contract between you and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide and improve the functionalities of the Sollinked Platform and Payment Services.</p>
                <ul>
                {/* <li><strong>Geo-location Information.</strong>&nbsp;When you use certain features of the Sollinked Platform, we may collect information about your precise or approximate location as determined through data such as your IP address or mobile device’s GPS to offer you an improved user experience. Most mobile devices allow you to control or disable the use of location services for applications in the device’s settings menu. Sollinked may also collect this information even when you are not using the app if this connection is enabled through your settings or device permissions.</li> */}
                <li><strong>Usage Information.&nbsp;</strong>We collect information about your interactions with the Sollinked Platform such as bookings you have made and other actions on the Sollinked Platform.</li>
                {/* <li><strong>Log Data and Device Information.</strong>&nbsp;We automatically collect log data and device information when you access and use the Sollinked Platform, even if you have not created an Sollinked Account or logged in. That information includes, among other things: details about how you’ve used the Sollinked Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Sollinked Platform.</li> */}
                <li><strong>Cookies and Similar Technologies.</strong>&nbsp;We use cookies and other similar technologies, such as web beacons, pixels, and mobile identifiers. We may also allow our business partners to use these tracking technologies on the Sollinked Platform, or engage others to track your behavior on our behalf. While you may disable the usage of cookies through your browser settings, the Sollinked Platform currently does not respond to a “Do Not Track” signal in the HTTP header from your browser or mobile application due to lack of standardization regarding how that signal should be interpreted.</li>
                {/* <li><strong>Payment Transaction Information.</strong>&nbsp;Sollinked collects information related to your payment transactions through the Sollinked Platform, including the payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, IBAN information, your address and other related transaction details. This information is necessary for the adequate performance of the contract between you and Sollinked and to allow the provision of the Payment Services.</li> */}
                </ul>
                <p><strong>1.3 Information We Collect from Third Parties.</strong></p>
                <p>Sollinked may collect information, including personal information, that others provide about you when they use the Sollinked Platform and the Payment Services, or obtain information from other sources and combine that with information we collect through the Sollinked Platform and the Payment Services. We do not control, supervise or respond for how the third parties providing your information process your personal data, and any information request regarding the disclosure of your personal information to us should be directed to such third parties.</p>
                <ul>
                <li><strong>Third Party Services.</strong>&nbsp;If you link, connect, or login to your Sollinked Account with a third party service (e.g. Google, Facebook, WeChat), the third party service may send us information such as your registration and profile information from that service. This information varies and is controlled by that service or as authorized by you via your privacy settings at that service.</li>
                <li><strong>Other Sources.</strong>&nbsp;To the extent permitted by applicable law, we may receive additional information about you, such as demographic data or fraud detection information, from third party service providers and/or partners, and combine it with information we have about you. For example, we may receive background check results (with your consent where required) or fraud warnings from service providers like identity verification services for our fraud prevention and risk assessment efforts. We may receive information about you and your activities on and off the Sollinked Platform through partnerships, or about your experiences and interactions from our partner ad networks.</li>
                </ul>
                <p><strong>2. HOW WE USE INFORMATION WE COLLECT</strong></p>
                <p>We use, store, and process information, including personal information, about you to provide, understand, improve, and develop the Sollinked Platform, create and maintain a trusted and safer environment and comply with our legal obligations.</p>
                <p><strong>2.1 Provide, Improve, and Develop the Sollinked Platform.</strong></p>
                <ul>
                <li>Enable you to access and use the Sollinked Platform.</li>
                <li>Operate, protect, improve, and optimize the Sollinked Platform and experience, such as by performing analytics and conducting research.</li>
                <li>Provide customer service.</li>
                <li>Send you service or support messages, updates, security alerts, and account notifications.</li>
                <li>If you provide us with your contacts’ information, we may process this information: (i) for fraud detection and prevention, and (ii) for any purpose you authorize at the time of collection.</li>
                <li>To operate, protect, improve, and optimize the Sollinked Platform and experience, and personalize and customize your experience (such as making Listing suggestions or ranking search results), we conduct profiling based on your interactions with the Sollinked Platform, your search and booking history, your profile information and preferences, and other content you submit to the Sollinked Platform.</li>
                </ul>
                <p>We process this information given our legitimate interest in improving the Sollinked Platform and our Members’ experience with it, and where it is necessary for the adequate performance of the contract with you.</p>
                <p><strong>2.2 Create and Maintain a Trusted and Safer Environment.</strong></p>
                <ul>
                <li>Detect and prevent fraud, spam, abuse, security incidents, and other harmful activity.</li>
                <li>Conduct security investigations and risk assessments.</li>
                {/* <li>Verify or authenticate information or identifications provided by you (such as to verify your Accommodation address or compare your identification photo to another photo you provide).</li> */}
                <li>Conduct checks against databases and other information sources, including background or police checks, to the extent permitted by applicable laws and with your consent where required.</li>
                <li>Comply with our legal obligations.</li>
                <li>Resolve any disputes with any of our Members and enforce our agreements with third parties.</li>
                <li>Enforce our&nbsp;<a href="https://www.sollinked.com/terms" style={{ color: 'lightblue' }}>Terms of Service</a>&nbsp;and other policies.</li>
                <li>In connection with the activities above, we may conduct profiling based on your interactions with the Sollinked Platform, your profile information and other content you submit to the Sollinked Platform, and information obtained from third parties. In limited cases, automated processes may restrict or suspend access to the Sollinked Platform if such processes detect a Member or activity that we think poses a safety or other risk to the Sollinked Platform, other Members, or third parties.</li>
                </ul>
                <p>We process this information given our legitimate interest in protecting the Sollinked Platform, to measure the adequate performance of our contract with you, and to comply with applicable laws.</p>
                <p><strong>2.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing.</strong></p>
                <ul>
                <li>Send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences (including information about Sollinked or partner campaigns and services) and social media advertising through social media platforms such as Facebook or Google).</li>
                <li>Personalize, measure, and improve our advertising.</li>
                <li>Administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by Sollinked or its third party partners.</li>
                <li>Conduct profiling on your characteristics and preferences (based on the information you provide to us, your interactions with the Sollinked Platform, information obtained from third parties, and your search and booking history) to send you promotional messages, marketing, advertising and other information that we think may be of interest to you.</li>
                </ul>
                <p>We will process your personal information for the purposes listed in this section given our legitimate interest in undertaking marketing activities to offer you products or services that may be of your interest. You can opt-out of receiving marketing communications from us by changing your notification settings within your Sollinked Account.</p>
                <p><strong>2.4 How the Data Controller uses the Information Collected.</strong></p>
                <ul>
                <li>Enable you to access and use the Payment Services.</li>
                <li>Detect and prevent fraud, abuse, security incidents, and other harmful activity.</li>
                <li>Conduct security investigations and risk assessments.</li>
                <li>Conduct checks against databases and other information sources.</li>
                <li>Comply with legal obligations (such as anti-money laundering regulations).</li>
                <li>Enforce the Payment Terms and other payment policies.</li>
                <li>With your consent, send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences.</li>
                </ul>
                <p>The Data Controller processes this information given its legitimate interest in improving the Payment Services and its users’ experience with it, and where it is necessary for the adequate performance of the contract with you and to comply with applicable laws.</p>
                <p><strong>3. SHARING &amp; DISCLOSURE</strong></p>
                <p><strong>3.1 With Your Consent.</strong></p>
                <p>Where you have provided consent, we share your information, including personal information, as described at the time of consent, such as when you authorize a third party application or website to access your Sollinked Account or when you participate in promotional activities conducted by Sollinked partners or third parties.</p>
                <p><strong>3.2 Profiles, Listings, and other Public Information.</strong></p>
                <p>The Sollinked Platform lets you publish information, including personal information, that is visible to the general public. For example:</p>
                <ul>
                <li>Parts of your public profile page, such as your first name, your description, and city, are publicly visible to others.</li>
                <li>If you submit content in a community or discussion forum, blog or social media post, or use a similar feature on the Sollinked Platform, that content is publicly visible.</li>
                </ul>
                <p>Information you share publicly on the Sollinked Platform may be indexed through third party search engines. In some cases, you may opt-out of this feature in your Account settings. If you change your settings or your public-facing content, these search engines may not update their databases. We do not control the practices of third party search engines, and they may use caches containing your outdated information.</p>
                <p><strong>3.3 Compliance with Law, Responding to Legal Requests, Preventing Harm and Protection of our Rights.</strong></p>
                <p>Sollinked may disclose your information, including personal information, to courts, law enforcement or governmental authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or if such disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with legal process and to respond to claims asserted against Sollinked, (iii) to respond to verified requests relating to a criminal investigation or alleged or suspected illegal activity or any other activity that may expose us, you, or any other of our users to legal liability, (iv) to enforce and administer our Terms of Service, the Payment Terms or&nbsp;<a href="https://www.sollinked.com/terms">other</a>&nbsp;agreements with Members, or (v) to protect the rights, property or personal safety of Sollinked, its employees, its Members, or members of the public.</p>
                <p>These disclosures may be necessary to comply with our legal obligations, for the protection of your or another person’s vital interests or for the purposes of our or a third party’s legitimate interest in keeping the Sollinked Platform secure, preventing harm or crime, enforcing or defending legal rights, or preventing damage.</p>
                <p>Where appropriate, we may notify Members about legal requests unless: (i) providing notice is prohibited by the legal process itself, by court order we receive, or by applicable law, or (ii) we believe that providing notice would be futile, ineffective, create a risk of injury or bodily harm to an individual or group, or create or increase a risk of fraud upon Sollinked’s property, its Members and the Sollinked Platform. In instances where we comply with legal requests without notice for these reasons, we will attempt to notify that Member about the request after the fact where appropriate and where we determine in good faith that we are no longer prevented from doing so.</p>
                <p><strong>3.4 Service Providers.</strong></p>
                <p>Sollinked uses a variety of third party service providers to help us provide services related to the Sollinked Platform and the Payment Services. Service providers may be located inside or outside of the European Economic Area (“<strong>EEA</strong>”).</p>
                <p>For example, service providers may help us: (i) verify your identity or authenticate your identification documents, (ii) check information against public databases, (iii) conduct background or police checks, fraud prevention, and risk assessment, (iv) perform product development, maintenance and debugging, (v) allow the provision of the Sollinked Services through third party platforms and software tools (e.g. through the integration with our APIs), or (vi) provide customer service, advertising, or payments services. These providers have limited access to your information to perform these tasks on our behalf, and are contractually bound to protect and to use it only for the purposes for which it was disclosed and consistent with this Privacy Policy.</p>
                <p>Sollinked will need to share your information, including personal information, in order to ensure the adequate performance of our contract with you.</p>
                <p><strong>3.5 Corporate Affiliates.</strong></p>
                <p>To enable or support us in providing the Sollinked Platform and the Payment Services, we may share your information, including personal information, within our corporate family of companies (both financial and non-financial entities) that are related by common ownership or control.</p>
                <p>Additionally, we share your information, including personal information, with our corporate affiliates in order to support and integrate, promote, and to improve the Sollinked Platform and our affiliates’ services.</p>
                <p><strong>3.6 Social Media Platforms.</strong></p>
                <p>Where permissible according to applicable law we may use certain limited personal information about you, such as your email address, to hash it and to share it with social media platforms, such as Facebook or Google, to generate leads, drive traffic to our websites or otherwise promote our products and services or the Sollinked Platform. These processing activities are based on our legitimate interest in undertaking marketing activities to offer you products or services that may be if your interest.</p>
                <p>The social media platforms with which we may share your personal data are not controlled or supervised by Sollinked. Therefore, any questions regarding how your social media platform service provider processes your personal data should be directed to such provider.</p>
                <p>Please note that you may, at any time ask Sollinked to cease processing your data for these direct marketing purposes by sending an e-mail to us.</p>
                <p><strong>3.7 Business Transfers.</strong></p>
                <p>If Sollinked undertakes or is involved in any merger, acquisition, reorganization, sale of assets, bankruptcy, or insolvency event, then we may sell, transfer or share some or all of our assets, including your information in connection with such transaction or in contemplation of such transaction (e.g., due diligence). In this event, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.</p>
                <p><strong>3.8 Aggregated Data.</strong></p>
                <p>We may also share aggregated information (information about our users that we combine together so that it no longer identifies or references an individual user) and other anonymized information for regulatory compliance, industry and market analysis, demographic profiling, marketing and advertising, and other business purposes.</p>
                <p><strong>4. OTHER IMPORTANT INFORMATION</strong></p>
                <p><strong>4.1 Analyzing your Communications.</strong></p>
                <p>We may review, scan, or analyze your communications on the Sollinked Platform for fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, and customer support purposes. In some cases, we may also scan, review, or analyze messages to debug, improve, and expand product offerings. We use automated methods where reasonably possible. However, occasionally we may need to manually review some communications, such as for fraud investigations and customer support, or to assess and improve the functionality of these automated tools. We will not review, scan, or analyze your communications to send third party marketing messages to you, and we will not sell reviews or analyses of these communications.</p>
                <p>These activities are carried out based on Sollinked’s legitimate interest in ensuring compliance with applicable laws and our Terms, preventing fraud, promoting safety, and improving and ensuring the adequate performance of our services.</p>
                <p><strong>4.2 Linking Third Party Accounts.</strong></p>
                <p>You may link your Sollinked Account with your account at a third party social networking service. Your contacts on these third party services are referred to as “Friends.” &nbsp;When you create this link:</p>
                <ul>
                <li>some of the information you provide to us from the linking of your accounts may be published on your Sollinked Account profile;</li>
                <li>your activities on the Sollinked Platform may be displayed to your Friends on the Sollinked Platform and/or that third party site;</li>
                <li>a link to your public profile on that third party social networking service may be included in your Sollinked public profile;</li>
                <li>other Sollinked users may be able to see any common Friends that you may have with them, or that you are a Friend of their Friend if applicable;</li>
                <li>the information you provide to us from the linking of your accounts may be stored, processed and transmitted for fraud prevention and risk assessment purposes; and</li>
                <li>the publication and display of information that you provide to Sollinked through this linkage is subject to your settings and authorizations on the Sollinked Platform and the third party site.</li>
                </ul>
                <p>We only collect your information from linked third party accounts to the extent necessary to ensure the adequate performance of our contract with you, or to ensure that we comply with applicable laws, or with your consent.</p>
                <p><strong>5. THIRD PARTY PARTNERS &amp; INTEGRATIONS</strong></p>
                <p>The Sollinked Platform may contain links to third party websites or services, such as third party integrations, co-branded services, or third party-branded services (“<strong>Third Party Partners</strong>”). Sollinked doesn’t own or control these Third Party Partners and when you interact with them, you may be providing information directly to the Third Party Partner, Sollinked, or both. These Third Party Partners will have their own rules about the collection, use, and disclosure of information. We encourage you to review the privacy policies of the other websites you visit.</p>
                <p><strong>6. YOUR RIGHTS</strong></p>
                <p>You may exercise any of the rights described in this section before your applicable Sollinked Data Controller by sending an email to us. Please note that we may ask you to verify your identity before taking further action on your request.</p>
                <p><strong>6.1 Managing Your Information.</strong></p>
                <p>You may access and update some of your information through your Account settings. If you have chosen to connect your Sollinked Account to a third-party application, like Facebook or Google, you can change your settings and remove permission for the app by changing your Account settings. You are responsible for keeping your personal information up-to-date.</p>
                <p><strong>6.2 Rectification of Inaccurate or Incomplete Information.</strong></p>
                <p>You have the right to ask us to correct inaccurate or incomplete personal information concerning you (and which you cannot update yourself within your Sollinked Account).</p>
                <p><strong>6.3 Data Retention and Erasure.</strong></p>
                <p>We generally retain your personal information for as long as is necessary for the performance of the contract between you and us and to comply with our legal obligations. If you no longer want us to use your information to provide the Sollinked Platform to you, you can request that we erase your personal information and close your Sollinked Account. Please note that if you request the erasure of your personal information:</p>
                <ul>
                <li>We may retain some of your personal information as necessary for our legitimate business interests, such as fraud detection and prevention and enhancing safety. For example, if we suspend an Sollinked Account for fraud or safety reasons, we may retain certain information from that Sollinked Account to prevent that Member from opening a new Sollinked Account in the future.</li>
                <li>We may retain and use your personal information to the extent necessary to comply with our legal obligations. For example, Sollinked may keep some of your information for tax, legal reporting and auditing obligations.</li>
                <li>Information you have shared with others (e.g., Reviews, forum postings) may continue to be publicly visible on the Sollinked Platform, even after your Sollinked Account is cancelled. However, attribution of such information to you will be removed. Additionally, some copies of your information (e.g., log records) may remain in our database, but are disassociated from personal identifiers.</li>
                <li>Because we maintain the Sollinked Platform to protect from accidental or malicious loss and destruction, residual copies of your personal information may not be removed from our backup systems for a limited period of time.</li>
                </ul>
                <p><strong>7. SECURITY</strong></p>
                <p>We are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration. Some of the safeguards we use to protect your information are firewalls and data encryption, and information access controls. If you know or have reason to believe that your Sollinked Account credentials have been lost, stolen, misappropriated, or otherwise compromised or in case of any actual or suspected unauthorized use of your Sollinked Account, please contact us following the instructions in the Contact Us section below.</p>
                <p><strong>8. CHANGES TO THIS PRIVACY POLICY</strong></p>
                <p>Sollinked reserves the right to modify this Privacy Policy at any time in accordance with this provision. If we make changes to this Privacy Policy, we will post the revised Privacy Policy on the Sollinked Platform and update the “Last Updated” date at the top of this Privacy Policy. We will also provide you with notice of the modification by email at least thirty (30) days before the date they become effective. If you disagree with the revised Privacy Policy, you may cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the Sollinked Platform will be subject to the revised Privacy Policy.</p>
                <p><strong>9. CONTACT US</strong></p>
                <p>If you have any questions or complaints about this Privacy Policy or Sollinked’s information handling practices, you may email&nbsp;us through our Contact Us page.</p>
            </div>
        )
    }
}

export default PrivacyPolicy;