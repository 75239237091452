import React, { createContext, useCallback, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useNavigate } from 'react-router';
import './App.scss';
import './keyframes.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ContactUs, Home, PrivacyPolicy, Terms } from './Pages';
import 'font-awesome/css/font-awesome.min.css';

const routes = [
	'/',
	'/gallery',
	// '/about-us',
	'/contact-us',
	'/privacy-policy',
	'/terms',
];

function App() {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [previousY, setPreviousY] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		if(!routes.includes(window.location.pathname)){
			navigate('/');
		}
	}, [navigate]);

	const toggleDrawerOpen = useCallback(() => {
		setPreviousY(window.scrollY);
		setDrawerOpen(!drawerOpen);

		if(drawerOpen) {
			//closing
			document.querySelector('#root')?.classList.remove('modal-open');
			document.querySelector('html')?.classList.add('auto');
			window.scrollTo(0, previousY);
			document.querySelector('html')?.classList.remove('auto');
		}

		else {
			//opening
			document.querySelector('#root')?.classList.add('modal-open');
		}
		
	}, [drawerOpen, previousY]);

	return (
		<div className={`App`}>
			<header className='app-header'>
				<div className="header-container">
					<div className='d-flex flex-row align-items-center justify-content-center'>
						<a href="/" className='logo'><img src="/logo.png" alt="logo" /></a>
						<strong className='ml-3'>Sollinked</strong>
					</div>
					<div className="link-container">
						{/* <a href="/gallery">Gallery</a> */}
						{/* <div className="divider"></div> */}
						{/* <a href="/about-us">About Us</a>
						<div className="divider"></div> */}
						{/* <a href="/contact-us">Contact Us</a> */}
						<a href="https://www.youtube.com/watch?v=L8tv4lmRXxg" target='_blank' rel="noopener noreferrer" className='demo-button'>Product Demo</a>
						<a href="https://app.sollinked.com" className='app-button'>Go to App</a>
					</div>
				</div>
			</header>

			{/* <button className={`drawer-button ${drawerOpen? 'active' : ''}`} onClick={toggleDrawerOpen}>
				<i className={`fa fa-${drawerOpen? 'times' : 'bars'}`}></i>
			</button> */}

			<div className={`mobile-drawer ${drawerOpen? 'active' : ''}`}>
				<div className="link-container">
					<a href="/" className={window.location.pathname === "/"? 'active' : ''}>Home</a>
					{/* <a href="/gallery" className={window.location.pathname === "/gallery"? 'active' : ''}>Gallery</a> */}
					{/* <a href="/about-us" className={window.location.pathname === "/about-us"? 'active' : ''}>About Us</a> */}
					{/* <a href="/contact-us" className={window.location.pathname === "/contact-us"? 'active' : ''}>Contact Us</a> */}
					<a href="https://app.sollinked.com" className='app-button'>Go to App</a>
					<a href="https://www.youtube.com/watch?v=L8tv4lmRXxg" target='_blank' rel="noopener noreferrer" className='app-button'>Product Demo</a>
				</div>
			</div>

			{/** Please update routes constant if there's a new page */}
			<Routes>
				<Route path="/" element={<Home />}></Route>
				{/* <Route path="/about-us" element={<AboutUs />}></Route> */}
				{/* <Route path="/contact-us" element={<ContactUs />}></Route> */}
				<Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
				<Route path="/terms" element={<Terms />}></Route>
				{/* <Route path="/gallery" element={<Gallery />}></Route> */}
			</Routes>

			<ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme={'colored'}
            />
			<footer>
				<div className="footer-container">
					<div className="company-details">
						<img src="/logo.png" alt="logo" />
						<div>{process.env.REACT_APP_COMPANY_NAME}</div>
					</div>
					<div className="site-map">
						{/* <strong>Products</strong> */}
						{/* <ul className='webpages'>
							<li><a href="/">Sollinked Mail</a></li>
							<li><a href="/">Gitgud</a></li>
							<li><a href="/">Sollinked SDK</a></li>
						</ul> */}
					</div>
					<div className="site-map">
						<strong>Sitemap</strong>
						<ul className='webpages'>
							<li><a href="/">Home</a></li>
							{/* <li><a href="/gallery">Gallery</a></li> */}
							{/* <li><a href="/about-us">About Us</a></li> */}
							{/* <li><a href="/contact-us">Contact Us</a></li> */}
							<li><a href="/privacy-policy">Privacy Policy</a></li>
							<li><a href="/terms">Terms</a></li>
							<li><a href="https://app.sollinked.com">Web App</a></li>
						</ul>
					</div>
				</div>
				<div className="footer-links">
					<div className="links-container">
						<a href="/privacy-policy">Privacy Policy</a>
						<a href="/terms">Terms</a>
					</div>
					<span>Copyright &copy; 2023 {process.env.REACT_APP_COMPANY_NAME} All Rights Reserved</span>
				</div>
			</footer>
		</div>
	);
}

export default App;
