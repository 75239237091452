import React from 'react';
import {Fade} from 'react-awesome-reveal';
import './styles.scss';

const Home = () => {
    return (
        <div className="home-page">
            <div className="bigger section">
                {/* <img src="/logo.png" alt="logo" className='bg'/> */}
                <div className="mask"></div>
                <div className="description-container py-5 px-2">
                    <h1>We've got everything you need to build<br/><span style={{ color: 'gold' }}>Your Gated Community</span></h1>
                    <div className='flex flex-row'>
                        <a href="https://app.sollinked.com" className='btn btn-sm text-uppercase'>
                            Start Your Community Today
                            <i className="fa fa-chevron-right ml-2"></i>
                        </a>
                        <a href="https://www.youtube.com/watch?v=L8tv4lmRXxg" className='btn btn-sm text-uppercase demo' target='_blank' rel="noopener noreferrer">
                            Product Demo
                            <i className="fa fa-chevron-right ml-2"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="free-size section mobile-height-unset">
                <div className="description-container">
                    <Fade>
                        <span className='product-title'>Gating Tools at Your Doorstep</span>
                        <div className="product-container">
                            <div className="icon-container">
                                <i className="fa fa-envelope"></i>
                            </div>
                            <div>
                                <span style={{ color: 'gold' }}>Email Filter</span>
                                <span>Stops unwanted emails in its tracks. Earn $USDC upon answering those emails.</span>
                            </div>
                        </div>
                        <div className="product-container">
                            <div className="icon-container">
                                <i className="fa fa-calendar"></i>
                            </div>
                            <div>
                                <span style={{ color: 'gold' }}>Scheduler</span>
                                <span>Get paid for showing up to a meeting.</span>
                            </div>
                        </div>
                        <div className="product-container">
                            <div className="icon-container">
                                <i className="fa fa-envelopes-bulk"></i>
                            </div>
                            <div>
                                <span style={{ color: 'gold' }}>Subscriptions</span>
                                <span>Send newsletters to paying customers.</span>
                            </div>
                        </div>
                        <div className="product-container">
                            <div className="icon-container">
                                <i className="fa fa-scroll"></i>
                            </div>
                            <div>
                                <span style={{ color: 'gold' }}>Paid Articles</span>
                                <span>Earn by producing quality contents.</span>
                            </div>
                        </div>
                        <div className="product-container">
                            <div className="icon-container">
                                <i className="fa fa-code"></i>
                            </div>
                            <div>
                                <span style={{ color: 'gold' }}>Sollinked SDK</span>
                                <span>Integrate Sollinked into your app effortlessly</span>
                            </div>
                        </div>
                        <div className="product-container">
                            <div className="icon-container">
                                <i className="fa fa-github"></i>
                            </div>
                            <div>
                                <span style={{ color: 'gold' }}>Github Bot</span>
                                <span>Enables payments to open source projects on GitHub. Paid service ensures the longevity of the projects.</span>
                            </div>
                        </div>
                        <a href="https://app.sollinked.com" className='inner-app-button' target='_blank' rel="noopener noreferrer">
                            Start Now! It's Free!
                            <i className="fa fa-chevron-right ml-3"></i>
                        </a>
                    </Fade>
                </div>
            </div>
            <div className="free-size section">
                <div className="description-container py-5 px-2">
                    <Fade>
                        <h2>Our Users Love It</h2>
                        <div className="image-container" style={{ maxWidth: 700 }}>
                            <img
                                src="/Media/feedback_3.png"
                                alt="feedback"
                            />
                            <div className="deco"></div>
                        </div>
                        <div className="image-container" style={{ maxWidth: 700 }}>
                            <img
                                src="/Media/feedback.png"
                                alt="feedback"
                            />
                            <div className="deco"></div>
                        </div>
                        <div className="image-container" style={{ maxWidth: 700 }}>
                            <img
                                src="/Media/feedback_1.png"
                                alt="feedback"
                            />
                            <div className="deco"></div>
                        </div>
                    </Fade>
                </div>
            </div>
            <div className="free-size section" style={{ paddingTop: 0 }}>
                <div className="description-container py-5 px-2">
                    <h2>What are you waiting for?</h2>
                    <a href="https://app.sollinked.com" className='btn btn-sm text-uppercase'>
                        Your Empire Awaits
                        <i className="fa fa-chevron-right ml-2"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Home;